/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@aws-amplify/ui-angular/theme.css';
@import url('https://fonts.googleapis.com/css2?family=Baumans&family=Gentium+Book+Plus&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import 'swiper/css/bundle';
@import 'swiper/css/pagination';
@import 'swiper/css/effect-fade';
@import 'swiper/css/scrollbar';
@import 'swiper/css/zoom';
@import 'swiper/css/keyboard';

.swiper-pagination-bullet {
    background-color: var(--ion-color-dark) !important;
}

* {
    font-family: 'Inter', sans-serif !important;
}

#input:active, #input.focus {
    border: none;
}

.amplify-*{
    border: none !important;
    background: #fff;
}

.amplify-*:hover{
    background: none;
}

.amplify-*:focus{
    background: none !important;
}

[data-amplify-authenticator] [data-amplify-container] {
    place-self: center !important;
    min-width: 90% !important;
    margin-bottom: 20% !important;
    margin-top: 5%;
}

[data-amplify-authenticator][data-amplify-container]{
    place-self: auto !important;
    width: 95%;
    margin-top: 5rem;
    justify-self: center;
}

[data-amplify-router]{
    border: none;
}

[data-amplify-authenticator] [data-amplify-router]{
    border: none;
    box-shadow: none;
    max-width: 450px;
    margin: auto;
}

[data-amplify-authenticator][data-variation=modal]{
    background: #fff;
    border: none;
}

[data-amplify-authenticator] [data-amplify-form]{
    background: #fff;
    padding: 1em;
}

[data-amplify-authenticator] [data-amplify-footer]{
    background: #fff;
}

.amplify-input {
    font-size: 0.75em;
    background-color: #ddd;
    border: 1px solid #ccc;
}

.amplify-input:focus {
    font-size: 0.75em;
    background-color: white;
    border: 0px solid #ccc !important;
}

.amplify-input:active {
    font-size: 0.75em;
    background-color: white;
    border: 0px solid #ccc !important;
}

.amplify-form-field:active, .amplify-text-field:focus, .amplify-form-field:focus, .amplify-field:active, .amplify-field:focus, .amplify-flex:focus, .amplify-field-group:focus {
    border: none !important;
}


.amplify-tabs-item[data-spacing=equal]{
    flex: none;
}

.amplify-image {
    height: 100px;
    max-width: 160px;
}
.amplify-text {
    font-family: 'Roboto', sans-serif;
}

.amplify-tabs {
    border-radius: none;
    background: none;
}

.amplify-tabs-item[data-state='active']{
    color: black;
    border: none;
    font-size: 0.85em;
}

.amplify-tabs-item[data-state='inactive']{
    color: darkgray;
    border: none;
    font-size: 0.85em;
    background-color: #00000000;
}

.amplify-heading {
    font-weight: 600;
    font-size: 1.25em;
}

.amplify-button[data-variation='primary'] {
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 1em;
    border: 2px solid black;
    font-size: 0.85em;
}

.amplify-button[ng-reflect-is-disabled='true']{
    background-color: gray;
    border: gray;
    color: white;
    pointer-events: none;
    text-decoration: none;
    border-radius: 1em;
    font-size: 0.85em;
}

.amplify-button[data-variation='link'] {
    color: #0095f6;
    font-weight: 700 !important;
    font-size: 0.75em;
}

.amplify-button[data-variation='link']:hover {
    color: #0095f6;
    background-color: none;
    background: none;
}

.amplify-button[data-variation='default']{
    border: none;
}

.amplify-button[data-variation='default']:hover{
    background: none;
    color: black;
    border: none;
}

.amplify-button[data-variation='default']:focus{
    background: none;
    color: black;
    border: none;
}

.amplify-tabs {
    border: none;
    background: #fff;
}


.loading-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 1.5em !important;
    --background: var(--ion-color-light) !important;
    --spinner-color: black;
    border-radius: 50% !important;
}

ion-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    --spinner-color: black;
}

ion-tab-button {
    --color: black !important;
    --color-selected: black !important;
}

.footer-scroll {
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding-top: 0.5em;
}

ion-title {
    color: black;
}

alert-wrapper.sc-ion-alert-ios {
    border-radius: 0px;
    box-shadow: none;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 90vw;
    min-width: var(--min-width);
    max-width: 500px;
    height: var(--height);
    min-height: var(--min-height);
    max-height: var(--max-height);
    background: var(--background);
    contain: content;
    opacity: 0;
    z-index: 10;
}

.infinite-loading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 1em 0em 0em 0em !important;
}

// vg-scrub-bar-current-time {
//     .background {
//         background-color: var(--ion-color-tertiary) !important;
//     }
// }


// .native-textarea.sc-ion-textarea-ios {
//     width: 48vw !important;
//     height: 100% !important;
// }